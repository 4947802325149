<template>
  <div id="login">
    <div class="login-main">
      <div class="logo">
        <img class="logo-img" src="../../assets/image/logo.png" />
      </div>
      <div class="title">
        欢迎登录阿六管理系统
      </div>
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="账号：" prop="user">
          <a-input placeholder="请填入登录账号" v-model="ruleForm.user" />
        </a-form-model-item>
        <a-form-model-item label="密码：" prop="pass" @keyup.enter="submitForm">
          <a-input
            placeholder="请填入登录密码"
            v-model="ruleForm.pass"
            type="password"
            @keyup.enter="submitForm"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 24, offset: 0 }">
          <a-button
            type="primary"
            @click="submitForm"
            :disabled="!ruleForm.user || !ruleForm.pass"
          >
            登录
          </a-button>
          <!-- <a-button type="primary" @click="onWechatLogin">
            微信登录
          </a-button> -->
        </a-form-model-item>
      </a-form-model>
    </div>
    <footer>
      <div class="text">
        广州有联网络科技有限公司
      </div>
      <div class="text">
        粤ICP备18156486号-1 | Powered by youlian
      </div>
    </footer>

    <iframe
      id="iframe"
      :src="wechatLink"
      width="800px"
      height="600px"
      frameborder="0"
      name="iframea"
      v-if="wechatLink"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      ruleForm: {
        user: '',
        pass: ''
      },
      rules: {
        user: {
          required: true,
          message: '请填入登录账号',
          trigger: 'blur'
        },
        pass: {
          required: true,
          message: '请填入登录密码',
          trigger: 'blur'
        }
      },
      wechatLink: ''
    }
  },
  methods: {
    submitForm() {
      const data = {
        username: this.ruleForm.user,
        password: this.ruleForm.pass
      }
      this.$axios.login(data).then((res) => {
        this.$message.success('登录成功')
        const val = res.data.data
        window.sessionStorage.setItem('token', val.token)
        setTimeout(() => {
          window.location.replace('/')
        }, 500)
      })
    },
    onWechatLogin() {
      // 微信登录
      this.$axios.wechatLogin().then((res) => {
        this.wechatLink = res.data.data.url
      })
    }
  }
}
</script>
<style scoped>
#login {
  min-height: 100vh;
  background: #409eff url('../../assets/image/logoBg.png') 0 100% repeat-x;
  display: flex;
  flex-flow: column;
}

.login-main {
  flex: 1;
  width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.logo {
  width: 160px;
  margin: 126px auto 0 auto;
}

.logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  width: 600px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  background: linear-gradient(
    270deg,
    rgba(64, 158, 255, 0.2) 0%,
    rgba(0, 125, 255, 0.3) 49%,
    rgba(64, 158, 255, 0.2) 100%
  );
}

.ant-form {
  width: 480px;
  background-color: #fff;
  border-radius: 6px;
  padding: 50px;
  margin: 0 auto;
}

.login-main >>> .ant-form-item:nth-child(3) {
  margin-top: 30px;
  margin-bottom: 0;
}

.login-main >>> .ant-form-item-label {
  line-height: 46px;
  margin-right: 15px;
}

.ant-select,
.login-main >>> .ant-select-selection--single,
.login-main >>> .ant-select-selection__rendered,
.login-main >>> .ant-select-selection-selected-value,
.ant-input {
  height: 46px;
  line-height: 44px;
}

.ant-btn {
  width: 100%;
  height: 46px;
  color: #fff;
  border-radius: 23px;
  background-color: #409eff;
  font-size: 18px;
  box-shadow: 0 2px 8px 0 rgba(0, 99, 201, 0.4);
}

.ant-btn-primary[disabled] {
  opacity: 0.3;
  box-shadow: none;
}

.ant-btn[disabled]:hover {
  opacity: 0.3;
  color: #fff;
  background-color: #409eff;
}

footer {
  width: 100%;
  height: 42px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  position: fixed;
  left: 0;
  bottom: 50px;
  z-index: 1;
}

footer .text {
  color: #fff;
  font-size: 15px;
}

#iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
</style>
